body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.carousel-item img{
  max-height: 650px;
  
  
  }

.saraswati-img img{
   border-radius: 20%;
}  
.card{
  height: 420px;
  margin-top: 20%;
  margin-bottom: 20%;
  
}
#card{
  border: solid rgb(219, 202, 202) 2px;
}
.about-block{
  margin-bottom: 10%;
  margin-top: 10%;
}
.feature-block{
  background-image: linear-gradient(
  45deg,
  hsl(240deg 100% 20%) 0%,
  hsl(269deg 100% 21%) 5%,
  hsl(285deg 100% 21%) 10%,
  hsl(298deg 100% 21%) 14%,
  hsl(310deg 100% 25%) 19%,
  hsl(318deg 100% 29%) 24%,
  hsl(324deg 100% 32%) 29%,
  hsl(329deg 100% 36%) 33%,
  hsl(333deg 100% 39%) 38%,
  hsl(336deg 100% 42%) 43%,
  hsl(340deg 99% 45%) 48%,
  hsl(351deg 86% 55%) 52%,
  hsl(0deg 93% 61%) 57%,
  hsl(9deg 100% 61%) 62%,
  hsl(17deg 100% 59%) 67%,
  hsl(24deg 100% 57%) 71%,
  hsl(31deg 100% 54%) 76%,
  hsl(38deg 100% 50%) 81%,
  hsl(42deg 100% 50%) 86%,
  hsl(47deg 100% 50%) 90%,
  hsl(51deg 100% 50%) 95%,
  hsl(55deg 100% 50%) 100%
);
padding-top: 5%;
}

.gallary-block{
  margin-top: 10%;
}

.rew-block{
  display: flex;
  width: 100%;
  
  margin-bottom: 10%;
  color:white;
  background-color: rgb(238, 187, 84);
  height: 700px;
  padding: 5%;
  text-align: center;
  
  
}
*{
  margin: 0;
  padding: 0;
}
.rew-block img{
  
  height: 300px;
  width: 300px;
  border-radius: 50%;
  
  
}

.custom-button {
  background-color: brown; /* Default button color */
  color: white; /* Text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer; /* Change cursor to pointer on hover */

  /* Transition effect for color change */
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color:rgb(238, 187, 84); /* Button color on hover */
}

.about-container {
  position: relative;
  width: 100%;
  height: 500px; 
  overflow: hidden; 
}

.about-text {
  position: absolute;
  top: 85%;
  left: 20%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px black;

}
.about-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;  
  overflow: auto; 
  padding: 20px; 
}
.about-text h1 {
  margin-bottom: 20px;
}


.Navbar-block{
background-color: rgb(0, 0, 0);
  
    border-radius: 0px;
    width: 100%;
 
}

.Form-block{
  margin-top: 5%;
 text-align: center;
}
.fblock-1{
  padding: 2%;
  border-radius: 70px;


}

#contact-1{
  text-align: center;
}

.contact-block{
  margin: 5%;
  margin-top: 200px;
}

.about-info{
  height: 150px;
  width: 300px;
}

/*////////////////////////////rezizing1/////////////////////////////*/

@media (min-width: 421px) and (max-width: 1600px) {
  .Navbutton {
    margin-left: 800px; 
    margin-right: 100px;
    color: rgb(189, 178, 178);
  }
  .sclogo {
    height: 100px; /* Adjust height for laptop screen */
    width: 200px; /* Adjust width for laptop screen */
    margin-right: 50px;
  } 
  .g-about{
    font-size: 18px;
  }

  
}

/*//////////////////////////////////////resizing2/////////////////////////////////////*/


@media (max-width: 896px) {
  .Navbutton {
    font-size: 10px;
    color: rgb(189, 178, 178);
    margin: 0; /* Remove all margins at mobile screen */
  }
  .sclogo {
    height: 50px;
    width: 80px;
    margin-right: 70px;
  }
  
  .saraswati-img{
    height: 200px;
    width: 200px;
    margin-left: 90px;
    margin-bottom: 30px;
  }

 .about-block{
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .card > img {
    height: 350px;
    width: 350px;
  }
  

.custom-row{
  flex-direction: column-reverse;
  margin: 1px;
}
.card-container{
  margin:auto!important;
 
}
.card {
  margin-left: 20px;
}

#card-gal{
  height: 350px!important;
  width: 350px !important;
}
.about-info{
  text-align: center;
  width: 350px;
  height: 120px;
}
#ab-text{
  text-align: center;
}
#in-btn{
  text-align: center;
}
.g-about{
  margin-left: 50px;
}
#more-vimi{
  margin-right: 50px;
  
}



}

/*///////////////////////resizing for small phone////////////////*/
@media (max-width: 400px) {

  .feature-block{
    display: flex!important;
    justify-content: center!important;

  }

  #f-p-para{
    font-size: 12px;
  }

  #fimg{
    height: 200px!important;
    width: 240px!important;
   
  }

  #re-img{
    height: 200px;
    width: 200px;
  }

  #ab-text{
    text-align: center;
  }
  #in-btn{
    text-align: center;
  }

  .re-text{
    font-size: 13px;
  }

  .saraswati-img{
    height: 150px;
    width: 150px;
   
  }

  .about-block{
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .about-info{
    text-align: center;
    width: 280px;
    height: 120px;
  }
  .lead{
    font-size: 13px!important;
  }
  .g-about{
    font-size: 14px;
    margin-left:30px;
  }

  .Navbutton {
    font-size: 8px;
    margin-left:15px ;
  }
  .g-tag{
    font-size: 20px;
    
  }

  .card {
  margin-left: 30px;
  height: 350px!important;
  width: 250px !important;
  
}


.card-img{
  width: 250px!important;
}

.card-img-top{
  width: 260px!important;
  height: 260px!important;
}

.card-text{
  font-size: 12px;
}

.founder-text{
  font-size: 15px;
  
}

#card-gal{
  height: 200px!important;
  width: 150px !important;
}

.Navbutton2{
  height: 95px;
}

#nav-btn{
  font-size: 12px;
  display: flex;
  
}

.sclogo {
 height: 30px;
 width: 50px;
  margin-right:10px;
}

#more-vimi{
 font-size: 12px;
  
}
#mi-img{
  margin-right:50px;
  
}

#mi-text{
  padding: 20px;
  
}

#vi-img{
  margin-right:70px;
  margin-left: 20px;
  
}

#vi-text{
  padding: 20px;
  
}

.text-body-emphasis{
  font-size: 19px;
}

#contacttext{
  font-size: 17px!important;
}

.contact-block{
  margin-top:70px ;
}

#contact-svg{
  margin-right: 10px!important;
}
#cardp{
  height: 200px!important;
  width: 250px!important;
 display: flex;
 justify-content: center;
 max-width: 100%;
}
}
/*////////////////////SE////////////////////////*/



/*//////////////////////////////////////*/


.card-container{
  margin-left: 80px;
}

.Navbar-block{
  background-color:#8d0039;

}
.Navbar-block2{
  background-color: #b30048;
  display:block;
  position:sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}



  .Navbar-block2 {
    background-color: #b30048;
    width: 100%;
    transition: all 0.3s ease-in-out; /* Smooth transition effect */
  
  }
  
  .Navbar-block2.sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow when the navbar is sticky */
  }
  
  /* Add any other styling you want for the Navbar2 */
  
  .slide-up {
    transform: translateY(-100%);
    transition: transform 0.5s ease;
  }
  
  .slide-down {
    transform: translateY(0);
    transition: transform 0.5s ease;
  }
  


/*///////////////////////////////////////////////////////////////////////////*/


  .page-heading {
    font-family: cursive; /* Set font family to cursive */
    color: #800020;
    text-align: center; /* Align heading text to center */
    margin-bottom: 30px; /* Add space below the heading */
  }
  
  .icon {
    font-size: 3rem;
    color: #007bff; /* Bootstrap primary color */
    transition: transform 0.3s ease-in-out; /* Add transition for smoother effect */
  }
  
  .icon:hover {
    transform: scale(1.2); /* Scale up the icon on hover */
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: bold; /* Add bold font weight for titles */
    color: #333; /* Darken the color for better contrast */
    transition: color 0.3s ease-in-out; /* Add transition for smoother effect */
  }
  
  .description {
    font-size: 1rem;
    color: #555; /* Darken the color for better readability */
    transition: color 0.3s ease-in-out; /* Add transition for smoother effect */
  }
  
  .description:hover {
    color: #007bff; /* Change text color on hover */
  }
  #FacultyPage-block{
    margin-top: 50px;
  }

 #opportunities-block{
  margin-top: 100px;
 }


 .sports-achievements {
  padding: 20px;
}

.sports-photos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sports-photo {
  margin: 10px;
  width: 300px;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.sports-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease; /* Add transition for transform property */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.sports-photo:hover .overlay {
  opacity: 1;
}

.sports-photo:hover img {
  transform: scale(1.1); /* Scale up the image on hover */
}

.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.info h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.info p {
  font-size: 14px;
  margin: 0;
}

.sports-achievements-heading {
  font-family: cursive; /* Set font family to cursive */
  color: #800020;
  text-align: center; /* Align heading text to center */
  margin-bottom: 20px; /* Add bottom margin to the heading */
}

.paragraph {
  margin-bottom: 20px; /* Add bottom margin to paragraphs */
}

/* Trophy grid styles */

.trophy-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
}

.trophy-item {
  text-align: center;
}

.trophy-item img {
  width: 250px; /* Increase the width */
  height: 250px; /* Increase the height */
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease; /* Add transition for transform property */
}

.trophy-item p {
  margin-top: 10px;
}

.trophy-item:hover img {
  transform: scale(1.1); /* Scale up the image on hover */
}



#SportPage-block{
  margin-top: 100px;
}

#More-block{
  margin-top: 100px;
}

.Navbutton2 ul li:hover {
  border-bottom: 1px solid rgb(62, 2, 2);
}

.call_btn {
  position: fixed;
  bottom: 10px;
  right: 15px;
  z-index: 99;
  width: 100px;
  height: 100px;
}

.whatsapp_btn {
  position: fixed;
  bottom: 10px;
  left: 15px;
  z-index: 99;
  width: 100px;
  height: 100px;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
  border-color: #cf840c;
  opacity: .5;
}

.coccoc-alo-ph-circle {
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, .4);
  animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  transition: all .5s;
  transform-origin: 50% 50%;
}

@keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1;
  }
  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5;
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1;
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2;
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2;
  }
  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2;
  }
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
  background-color: rgb(251 176 59 / 85%);
  opacity: .75 !important;
}

.coccoc-alo-ph-circle-fill {
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid transparent;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  transition: all .5s;
  transform-origin: 50% 50%;
}

.coccoc-alo-ph-img-circle {
  width: 60px;
  height: 60px;
  top: 20px;
  left: 20px;
  position: absolute;
  border-radius: 100%;
  border: 2px solid transparent;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  transform-origin: 50% 50%;
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
      transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
      transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
      transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
      transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
      transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
      transform: rotate(0) scale(1) skew(1deg);
  }
}














